import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography } from 'components/Contentful/Typography'
import { BLOCKS } from '@contentful/rich-text-types'
import Section from 'components/Section'
import styles from './ProblemStatement.module.scss'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { AchieveTheme } from '@achieve/ascend'

function ProblemStatement({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const { title, subtitle } = useMemoizedContentGetter(content, ['title', 'subtitle'])
  return (
    <Section
      className={styles['section']}
      contain={true}
      backgroundColor={AchieveTheme?.ascend?.colors?.blue[500]}
    >
      {title && (
        <Typography
          data-testid="problem-statement-title-text"
          content={title.textContent}
          variant={isMobile ? 'headingLg' : 'displayLg'}
          fontWeight="bold"
          className={styles['title']}
        />
      )}
      {subtitle && (
        <Typography
          data-testid="problem-statement-subtitle-text"
          content={subtitle.textContent}
          variantOverride={{ [BLOCKS.PARAGRAPH]: isMobile ? 'bodyLg' : 'displaySm' }}
          fontWeight={isMobile ? 'medium' : 'regular'}
          className={styles['subtitle']}
        />
      )}
    </Section>
  )
}
export { ProblemStatement }
export default ProblemStatement
